import { createRoot } from 'react-dom/client';
import * as React from 'react';
import { store } from 'Shared/ifixit_store';
import { Provider } from 'react-redux';
import { withErrorInfo } from 'Shared/logging';
import { initializeReactQuery } from 'Shared/react-query-initialize';
import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';

const getPlaceholders = name => {
   return document.querySelectorAll(`.react-component[data-name="${name}"]`);
};

const initializeComponentForPlaceholder = (placeholder, Component, props) => {
   withErrorInfo(() => {
      const root = createRoot(placeholder);
      root.render(
         initializeReactQuery(
            <StyleSheetManager shouldForwardProp={shouldForwardProp} enableVendorPrefixes>
               <Provider store={store}>
                  <Component {...props} />
               </Provider>
            </StyleSheetManager>
         )
      );
   }, ['Error occurred while rendering', Component, 'with props', props]);
};

/**
 * This will mount React component `Component` in placeholder divs that have
 * a data-name of `name`.
 *
 * This is used by the ReactComponent.php class to indicate mount points.
 */
const initializeComponent = (name, Component, defaultProps) => {
   onDomReady(function () {
      let placeholders = getPlaceholders(name);

      Array.convert(placeholders).forEach(placeholder => {
         // eslint-disable-next-line no-console
         console.time(name);
         let props = {
            ...defaultProps,
            ...JSON.parse(placeholder.getAttribute('data-props')),
         };

         initializeComponentForPlaceholder(placeholder, Component, props);
         // eslint-disable-next-line no-console
         console.timeEnd(name);
      });
   });
};

// This implements the default behavior from styled-components v5
export function shouldForwardProp(propName, target) {
   if (typeof target === 'string') {
      // For HTML elements, forward the prop if it is a valid HTML attribute
      return isPropValid(propName);
   }
   // For other elements, forward all props
   return true;
}

export function createComponent(Component, props) {
   const placeholder = document.createElement('div');
   placeholder.className = 'react-component';
   initializeComponentForPlaceholder(placeholder, Component, props);
   return placeholder;
}

/** Reexport our Redux store, because some components expect it defined here */
export { store };

export default initializeComponent;
